import React from "react";
import { Container, Grid } from "theme-ui";
import Reveal from "@solid-ui-components/Reveal";
import ContentImages from "@solid-ui-components/ContentImages";
import BlogPost from "@solid-ui-blocks/Blog/Post";
import BlogPostVideo from "@solid-ui-blocks/Blog/PostVideo";
import Divider from "@solid-ui-components/Divider";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";

const styles = {};

const NewsBlock = ({
  contentHeader,
  contentLeft,
  contentRight,
}) => (
  <Container>
      {/* Top image */}
      <Divider space="3" />
      <ContentImages
        content={{ images: contentHeader.images }}
        imgStyle={{ width: '100%' }}
      />
      <Divider space="4" />

      {/* Blogs */}
      <Grid gap={4} columns={[1, 1, 1, "65% auto"]}>
        {/* Blog Left side Items */}
          <Reveal effect="fadeInLeft" threshold={0.7}>
            <Grid gap={4} columns={[1]}>
              {contentLeft.collection?.map((props, index) => (
                props.video ?
                  <BlogPostVideo key={`left-item-${index}`} {...props} />
                  :
                  <BlogPost key={`left-item-${index}`} {...props} />
              ))}
            </Grid>
          </Reveal>
          {/* Blog Right side Items */}
          <Reveal effect="fadeInRight" threshold={0.7}>
            <Grid gap={4} columns={[1]}>
                {contentRight.collection?.map((childProps, indexChild) => (
                  childProps.video ?
                    <BlogPostVideo key={`right-item-${indexChild}`} {...childProps}/>
                    :
                    <BlogPost key={`right-item-${indexChild}`} {...childProps} />
                ))}
            </Grid>
          </Reveal>
      </Grid>
  </Container>
);

export default WithDefaultContent(NewsBlock);
