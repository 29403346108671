import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import Tabs from "@solid-ui-components/Tabs";
import Process from "@solid-ui-blocks/Features/Block03";
import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block02";
import FeatureTwo from "@solid-ui-blocks/FeaturesWithPhoto/Block05";
import ListBlock from "@solid-ui-blocks/FeaturesWithPhoto/ListBlock";
import BoardMembers from "@solid-ui-blocks/Testimonials/BoardMembers";
import NewsBlock from "@solid-ui-blocks/FeaturesWithPhoto/NewsBlock";
import Strategies from "@solid-ui-blocks/Stats/Block01";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import AppWrapper from '@helpers/appContext';
import theme from "./_theme";

const AboutUs = (props) => {
  const { selectedLanguage } = props;
  const { allBlockContent } = useStaticQuery(query);

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  return (
    <Layout theme={theme} {...props}>
      <Seo title="About Us" />
      <Divider space="5" />
      <Divider space="5" />

      <Container variant="wide">
        <Tabs space={2}>
          {/* About-us tab */}
          <div content={content[`section-one.${selectedLanguage}`]}>
            <FeatureOne content={content[`section-one.${selectedLanguage}`]} />
            <Divider space="2" />
            <FeatureTwo
              content={content[`section-two.${selectedLanguage}`]}
              reverse
            />
            <Divider space="5" />
            <Process content={content[`process.${selectedLanguage}`]} />
          </div>

          {/* Board of advisors */}
          <div content={content[`board-of-advisors.${selectedLanguage}`]}>
            <Divider space="3" />
            <BoardMembers
              content={content[`board-of-advisors.${selectedLanguage}`]}
              selectedLanguage={selectedLanguage}
            />
          </div>

          {/* Our Partners */}
          <div content={content[`partner-tab-feature.${selectedLanguage}`]}>
            <Divider space="3" />
            <ListBlock
              content={content[`partner-tab-feature.${selectedLanguage}`]}
            />
          </div>

          {/* Our Team */}
          <div content={content[`team-tab-feature.${selectedLanguage}`]}>
            <Divider space="3" />
            <BoardMembers
              content={content[`team-tab-feature.${selectedLanguage}`]}
              selectedLanguage={selectedLanguage}
            />
          </div>

          {/* News  */}
          <div content={content[`news-tab-header.${selectedLanguage}`]}>
            <NewsBlock
              contentHeader={content[`news-tab-header.${selectedLanguage}`]}
              contentLeft={content[`news-tab-feature-left.${selectedLanguage}`]}
              contentRight={content[`news-tab-feature-right.${selectedLanguage}`]}
            />
          </div>
        </Tabs>
      </Container>

      <Divider space="5" />

      <Strategies content={content[`book-demo.${selectedLanguage}`]} />

      <Divider space="5" />
    </Layout>
  );
};

const query = graphql`
  query siteAboutUsBlockContent {
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/about-us/section-one"
            "site/about-us/section-two"
            "site/about-us/process"
            "site/about-us/partners"
            "site/about-us/team"
            "site/about-us/news/news-tab-header"
            "site/about-us/news/news-tab-feature-left"
            "site/about-us/news/news-tab-feature-right"
            "site/about-us/board"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

const AboutUsPage = (props) => (
  <AppWrapper>
    <AboutUs {...props}/>
  </AppWrapper>
);

export default AboutUsPage;