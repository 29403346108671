import React from "react";
import ReactModal from "react-modal";
import { IconButton, css, useThemeUI } from "theme-ui";
import { FaTimes } from "react-icons/fa";
import "./styles.css";

const styles = (theme) => ({
  modalContent: {
    borderRadius: theme.radii.lg,
    bg: theme.colors.contentBg,
    maxWidth: theme.sizes.container,
    overflow: `hidden`,
    py: 5,
    px: 4,
  },
  close: {
    position: `absolute`,
    // top: -5,
    // right: [3, null, null, -2],
    top: 2,
    right: 2,
    size: theme.sizes.icon.sm,
    svg: {
      size: theme.sizes.icon.sm,
    },
    ":hover": {
      color: theme.colors.omegaLighter,
    },
  },
});

ReactModal.setAppElement("#___gatsby");

const Modal = ({
  children,
  contentStyles,
  activeModal,
  handleClose,
  ...props
}) => {
  const { theme } = useThemeUI();
  return (
    <>
      <ReactModal
        isOpen={activeModal}
        closeTimeoutMS={300}
        onRequestClose={handleClose}
        className="ModalPortalContent"
        overlayClassName="ModalPortalOverlay"
        shouldFocusAfterRender={false}
        css={css({ ...styles(theme).modalContent, ...contentStyles })}
        {...props}
      >
        <IconButton
          onClick={handleClose}
          css={css(styles(theme).close)}
          aria-label="Close Modal"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        >
          <FaTimes />
        </IconButton>
        {children}
      </ReactModal>
    </>
  );
};

export default Modal;
