import React from "react";
import { Box, IconButton, css, useThemeUI } from "theme-ui";
import ContentImages from "@solid-ui-components/ContentImages";
import ContentText from "@solid-ui-components/ContentText";
import ContentContainer from "@solid-ui-components/ContentContainer";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import { FaArrowRight } from "react-icons/fa";

const styles = (theme) => ({
  card: {
    p: 3,
    pb: 5,
    height: `auto`,
    minWidth: 300,
    cursor: `pointer`,
    bg: theme.colors.omegaLighter,
    boxSizing: 'border-box',
    position: 'relative',
    ':hover': {
      button: {
        right: 15,
        color: theme.colors.white,
        bg: theme.colors.omegaDarker,
        borderColor: theme.colors.omegaDarker,
      }
    }
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: 'block',
    height: theme.sizes.full,
  },
  paragraph: {
    color: theme.colors.text
  },
  arrowBtn: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    border: '1px solid',
    width: 40,
    height: 40,
    borderWidth: theme.borderWidths.sm,
    borderColor: theme.colors.omegaDarker,
    color: theme.colors.omegaDarker,
  },
});

const BlogPost = ({
  container,
  images,
  kicker,
  title,
  description,
}) => {
  const { theme } = useThemeUI();
  return (
    <Box
      variant="cards.interactive"
      css={css(styles(theme).card)}
    >
      <ContentContainer content={container}>
        <Box>
          {images?.length > 0 && (
            <Box css={styles(theme).imageWrapper}>
              <ContentImages content={{ images }} />
            </Box>
          )}
          {kicker && <ContentText content={kicker} variant="h5" />}
          <ContentText content={title} variant="h6" />
          <ContentText
            content={description}
            variant="p"
            css={styles(theme).paragraph}
          />
        </Box>
        <IconButton
          onClick={null}
          aria-label="Post Link Button"
          sx={styles(theme).arrowBtn}
        >
          <FaArrowRight />
        </IconButton>
      </ContentContainer>
    </Box>
  );
};

export default WithDefaultContent(BlogPost);
