import React from "react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { Flex, Box, css, useThemeUI } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import ContentContainer from "@solid-ui-components/ContentContainer";
import ContentImages from "@solid-ui-components/ContentImages";
import getImage from "@solid-ui-components/utils/getImage";
import PlayerModal from "@solid-ui-blocks/Modal/PlayerModal";

const styles = (theme) => ({
  card: {
    p: 3,
    pb: 1,
    height: `auto`,
    minWidth: 300,
    cursor: `pointer`,
    bg: theme.colors.omegaLighter,
    // ':hover': {
    //   '& .video-play-button':{
    //     color: `black !important`,
    //   } 
    // }
  },
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: theme.sizes.full,
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 4,
    pb: 2
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: 'block',
    height: theme.sizes.full,
  },
  avatar: {
    size: 42,
    bg: theme.colors.omegaLighter,
    borderRadius: theme.radii.full,
    borderWidth: `md`,
    borderColor: theme.colors.omegaLighter,
    boxSizing: `content-box`,
    img: {
      objectPosition: "top center !important",
    },
  },
});

const BlogPostVideo = ({
  title,
  description,
  avatar,
  images,
  video,
  file,
  container,
}) => {
  const { theme } = useThemeUI();
  return (
    <ContentContainer
      content={container}
      variant="cards.interactive"
      css={css(styles(theme).card)}
    >
      <Flex as="article" css={styles(theme).content}>
        {/* Title */}
        <ContentText
          content={title}
          variant="h5"
          sx={{ textAlign: 'start', flex: [0, 0, `auto`]}}
        />
        {/* Video */}
        <Box css={styles(theme).imageWrapper}>
          <ContentImages content={{ images }} />
          {video?.src?.publicURL &&
            <Box
              style={{
                position: "absolute",
                top: 'calc(50% - 24px)',
                left: 'calc(50% - 32px)'
              }}
            >
              <PlayerModal video={video} />
            </Box>
          }
        </Box>
        <Box css={css(styles(theme).body)}>
          {/* Excerpt */}
          <ContentText
            content={description}
            variant="small"
            sx={{ flex: `auto`, mb: 4 }}
          />
          {/* Footer */}
          <Flex sx={{ alignItems: 'center' }}>
            <Img
              image={getImage(avatar?.src)}
              alt={avatar?.alt}
              css={css(styles(theme).avatar)}
            />
            {file?.src?.publicURL && <DownloadLink file={file} space={2} />}
          </Flex>
        </Box>
      </Flex>
    </ContentContainer>
  );
};

export default BlogPostVideo;