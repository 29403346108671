import React from "react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { Container, Box, css, useThemeUI } from "theme-ui";
// import Modal from "@solid-ui-components/Modal";
import BoardMemberModal from "@solid-ui-components/BoardMemberModal";
import getImage from "@solid-ui-components/utils/getImage";
import Reveal from "@solid-ui-components/Reveal";
import ContentContainer from "@solid-ui-components/ContentContainer";
import ContentText from "@solid-ui-components/ContentText";

const styles = (theme) => ({
  contentContainer: {
    textAlign: `center` 
  },
  imageWrapper: {
    width: 140,
    height: 140,
    mx: `auto`,
    mt: -90,
    mb: 3,
  },
  avatar: {
    width: 103.36,
    height: 107.24,
    display: `block`,
    bg: `transparent`,
    borderTopColor: `transparent`,
    borderTopWidth: theme.borderWidths.lg,
    borderTopStyle: `solid`,
    borderRadius: "20px",
    boxSizing: `content-box`,
    mx: `auto`,
  },
  paragraph: {
    textAlign: 'left'
  },
});
// content: { identifier, kicker, title, description, images, map, collection, form, avatar, buttons, }, id, reverse,

const ModalBlock02 = ({
  kicker,
  title,
  description,
  avatar,
  activeModal,
  handleClose,
  id,
}) => {
  const { theme } = useThemeUI();
  return (
    <BoardMemberModal
      activeModal={activeModal}
      handleClose={handleClose}
      contentStyles={{ maxWidth: "80%" }}
    >
      <Container sx={{ position: `relative` }}>
        <Box
          key={`item-${id}`}
          sx={{ flexBasis: [`1`, null, `1/2`, `1/3`], flexGrow: 1, p: 3 }}
        >
          <Reveal effect="fadeInLeft">
            <ContentContainer
              variant="cards.paper"
              sx={styles(theme).contentContainer}
            >
              <Box sx={styles(theme).imageWrapper}>
                <Img
                  image={getImage(avatar?.src)}
                  alt={avatar?.alt}
                  objectPosition="top center"
                  css={css(styles(theme).avatar)}
                />
              </Box>
              <ContentText content={kicker} variant="h5" />
              <ContentText content={title} variant="h6" />
              <ContentText
                content={description}
                variant="p"
                sx={styles(theme).paragraph}
              />
            </ContentContainer>
          </Reveal>
        </Box>
      </Container>
    </BoardMemberModal>
  );
};

export default ModalBlock02;
