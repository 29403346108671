import React, { useState } from "react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { Container, Box, css, useThemeUI } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import ContentContainer from "@solid-ui-components/ContentContainer";
import Reveal from "@solid-ui-components/Reveal";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import getImage from "@solid-ui-components/utils/getImage";
// import ContentButtons from "@solid-ui-components/ContentButtons";
import BoardMemberModal from "@solid-ui-blocks/Modal/BoardMemberModal";

const styles = (theme) => ({
  contentContainer: {
    textAlign: `center`
  },
  imageWrapper: {
    width: 140,
    height: 140,
    mx: `auto`,
    mt: -90,
    mb: 3,
  },
  avatar: {
    width: 103.36,
    height: 107.24,
    display: `block`,
    bg: `transparent`,
    borderTopColor: `transparent`,
    borderTopWidth: theme.borderWidths.lg,
    borderTopStyle: `solid`,
    borderRadius: "20px",
    boxSizing: `content-box`,
    mx: `auto`,
  },
  paragraph: {
    display: "-webkit-box",
    WebkitLineClamp: 6,
    WebkitBoxOrient: "vertical",
    maxHeight: "200px",
    overflow: "hidden",
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontFamily: "Fira Sans",
    fontWeight: "600",
  },
});

const BoardMember = ({
  container,
  avatar,
  kicker,
  title,
  description,
  // index,
  selectedLanguage,
}) => {
  const { theme } = useThemeUI();
  const [activeModal, setActiveModal] = useState(false);

  const handleShowModal = () => {
    setActiveModal(true);
  };

  return (
    <Container>
      <Reveal effect="fadeInLeft" delay={0.5}>
        <ContentContainer
          content={container}
          variant="cards.paper"
          sx={styles(theme).contentContainer}
        >
          <Box sx={styles(theme).imageWrapper}>
            <Img
              image={getImage(avatar?.src)}
              alt={avatar?.alt}
              objectPosition="top center"
              css={css(styles(theme).avatar)}
            />
          </Box>
          <ContentText content={kicker} variant="h5" />
          <ContentText content={title} variant="h6" />
          <ContentText
            content={description}
            variant="p"
            sx={styles(theme).paragraph}
          />

          {/* <ContentButtons onClick={setMemberDetails} content={buttons} space={0} /> */}
          <button style={styles(theme).button} onClick={handleShowModal}>
            {selectedLanguage === 'ja' ? 'もっと詳しく知る' : 'Learn More'}
          </button>
        </ContentContainer>
      </Reveal>

      <BoardMemberModal
        kicker={kicker}
        title={title}
        description={description}
        avatar={avatar}
        activeModal={activeModal}
        handleClose={() => setActiveModal(null)}
      />
    </Container>
  );
};

export default WithDefaultContent(BoardMember);
