import React from "react";
import { Container, Grid, Box } from "theme-ui";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import BoardMember from "./BoardMember/BoardMember";

const BoardMembers = ({ content: { collection }, selectedLanguage }) => (
  <Container>
    <Grid sx={{ m: -3, px: 3 }} columns={[1, 2, 2, 3]} gap={3.9}>
      {collection?.map(
        ({ container, avatar, kicker, title, description }, index) => (
          <Box
            key={`item-${index}`}
            sx={{ py: 5 }}
          >
            <BoardMember
              container={container}
              avatar={avatar}
              kicker={kicker}
              title={title}
              description={description}
              index={index}
              selectedLanguage={selectedLanguage}
            />
          </Box>
        )
      )}
    </Grid>
  </Container>
);

export default WithDefaultContent(BoardMembers);
